import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Grid } from "../components/grid/grid"
import Subfooter from "../components/footer/subfooter"


const CookiesPage = () => (
  <Layout>
    <SEO title="Política de cookies" />

    <Container>
      <Grid>
        <div class="l-start-1 l-end-11 m-start-1 m-end-5">
          <h1>Política de cookies</h1>
          <p>En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, esta página web le informa, en esta sección, sobre la política de recogida y tratamiento de cookies.</p>
        
          <h2 class="margintop2">¿Qué son las cookies?</h2>
          <p>Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
        
          <h2 class="margintop2">¿Qué tipos de cookies utiliza esta página web?</h2>
          <p>Esta página web utiliza los siguientes tipos de cookies:</p>
          <ul class="margintop1">
            <li>Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</li>
            <li>Cookies de personalización: Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma o el tipo de navegador a través del cual se conecta al servicio.</li>
          </ul>

          <h2 class="margintop2">Desactivar las cookies</h2>
          <p>Puedes permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en tu ordenador.</p>
          <p>En la mayoría de los navegadores web se ofrece la posibilidad de permitir, bloquear o eliminar las cookies instaladas en tu equipo.</p>
          <p>A continuación puedes acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:</p>

          <ul class="margintop1">
            <li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noreferrer noopener">Configurar cookies en Google Chrome</a></li>
            <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noreferrer noopener">Configurar cookies en Mozilla Firefox</a></li>
            <li><a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noreferrer noopener">Configurar cookies en Safari (Apple)</a></li>
            <li><a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noreferrer noopener">Configurar cookies en Microsoft Internet Explorer</a></li>
          </ul>

          <h2 class="margintop2">Cookies de terceros</h2>
          <p>Esta página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web.</p>
          <p>En concreto, usamos los servicios de Google Analytics, Google Tag Manager y Google Optimize para nuestras estadísticas y publicidad. Algunas cookies son esenciales para el funcionamiento del sitio.</p>
        
          <h2 class="margintop2">Advertencia sobre eliminar cookies</h2>
          <p>Si tienes cualquier duda acerca de nuestra política de cookies, puedes contactar con Parquick a través de info@parquick.com.</p>
       
          <h2 class="margintop2">Legitimación para el tratamiento de datos</h2>
          <p>La base legal para el tratamiento de tus datos es: el consentimiento.</p>
          <p>Para contactar con el Titular, suscribirte a un boletín o realizar comentarios en este sitio Web tienes que aceptar la presente Política de Privacidad.</p>

          <h2 class="margintop2">Categorías de datos personales</h2>
          <p>Las categorías de datos personales que trata el Titular son:</p>
          <ul class="margintop1">
            <li>Correo electrónico</li>
          </ul>

          <h2 class="margintop2">Conservación de datos personales</h2>
          <p>Los datos personales que proporciones al Titular se conservarán hasta que solicites su supresión.</p>

          <h2 class="margintop2">Destinatarios de datos personales:</h2>
          <ul>
            <li>Google Analytics es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Encontrarás más información en: https://analytics.google.com Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.</li>
            <li>Getform es un servicio de formularios prestado por Getform Ltd. Utilizado para recopilar y almacenar los datos emitidos desde el formulario de contacto.</li>
          </ul>

          <h2 class="margintop2">Navegación Web</h2>
          <p>Al navegar por www.parquick.com se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte.</p>
          <p>El sitio Web utiliza los siguientes servicios de análisis de terceros:</p>
          <ul class="margintop1">
            <li>Google Analytics</li>
            <li>Google Optimize</li>
          </ul>
          <p>El Titular utiliza la información obtenida para obtener datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y para recopilar información demográfica.</p>

          <h2 class="margintop2">Exactitud y veracidad de los datos personales</h2>
          <p>Te comprometes a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
          <p>Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando a el Titular de cualquier responsabilidad al respecto.</p>

          <h2 class="margintop2">Aceptación y consentimiento</h2>
          <p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por parte de el Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.</p>

          <h2 class="margintop2">Revocabilidad</h2>
          <p>Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a info@parquick.com junto con la prueba válida.</p>
          <p>El ejercicio de tus derechos no incluye ningún dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.</p>

          <h2 class="margintop2">Cambios en la Política de Privacidad</h2>
          <p>El Titular se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.</p>
          <p>Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
        </div>
      </Grid>
    </Container>
    <Subfooter />
  </Layout>
)

export default CookiesPage
